import { RichText } from 'components/shared';
import { articleRichTextOptions } from 'components/shared/articleAccordion';
import { defaultLocale } from 'lib/storeLocales/storeLocales';
import { capitalize } from 'lib/utils/helpers';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { renderToStaticMarkup } from 'react-dom/server';

const baseUrl = process.env.NEXT_PUBLIC_SITEURL;
const siteName = process.env.NEXT_PUBLIC_SITENAME;

function filterArticlesWithoutTableNode(articles) {
  return articles.filter(article => {
    // Check if any child in article.json.content has nodeType 'table'
    return !article.article.json.content.some(content => content.nodeType === 'table');
  });
}

function extractParagraphText(article) {
  let combinedText = '';

  // Recursive function to traverse content array
  function traverseContent(contentArray) {
    contentArray.forEach(content => {
      if (content.nodeType === 'paragraph') {
        // Concatenate all 'value' inside the 'paragraph' node
        content.content.forEach(innerContent => {
          if (innerContent.nodeType === 'text' && innerContent.value) {
            combinedText += innerContent.value;
          }
        });
      }
      // Traverse deeper if content has nested elements
      if (content.content && Array.isArray(content.content)) {
        traverseContent(content.content);
      }
    });
  }

  // Start traversing the content
  if (article.json && article.json.content) {
    traverseContent(article.json.content);
  }

  return combinedText.trim(); // Remove any trailing whitespace
}

const FaqStructuredData = ({ faqs }) => {
  const { asPath, locale } = useRouter();

  const filteredFAQs = filterArticlesWithoutTableNode(faqs);
  const pathWithoutQueryOrAnchor = `${asPath.split('/').slice(0, -1).join('/')}/`;
  const localePath = locale && locale !== defaultLocale ? `/${locale}` : '';
  const url = `${baseUrl}${localePath}${pathWithoutQueryOrAnchor}`;

  const mainEntity = filteredFAQs.map(({ title, article }) => {
    const stringifiedArticle = extractParagraphText(article);
    return {
      '@type': 'Question',
      name: title,
      acceptedAnswer: {
        '@type': 'Answer',
        text: stringifiedArticle,
      },
    };
  });

  return (
    <Head>
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity,
          }),
        }}
      />
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'WebPage',
            name: capitalize(siteName),
            url,
          }),
        }}
      />
    </Head>
  );
};

export default FaqStructuredData;
