import { useRef } from 'react';
import { useRouter } from 'next/router';
import { Image as SiteImage, ArticleAccordion, RichText, SiteLink } from 'components/shared';
import { filterByType, createMediaGetterFromCollection } from 'lib/utils/helpers';
import { BLOCKS } from '@contentful/rich-text-types';
import { isString } from 'underscore';
import CustomSelect from 'react-dropdown-select';
import { HEADER_HEIGHT } from '../../../constants';
import FaqStructuredData from './faqStructuredData';

const addSegmentNewLines = segment => {
  if (isString(segment)) {
    return segment.split('\n').reduce((segments, textSegment, i) => {
      // eslint-disable-next-line react/no-array-index-key
      return [...segments, i > 0 && <br key={i} />, textSegment];
    }, []);
  }

  return segment;
};

export const addNewLines = segments => segments.map(addSegmentNewLines);

// eslint-disable-next-line no-unused-vars
const richTextOptions = richText => {
  return {
    renderNode: {
      [BLOCKS.UL_LIST]: (_, children) => (
        <ul className="mb-[25px] list-disc pl-[25px]">{children}</ul>
      ),
      [BLOCKS.LIST_ITEM]: (_, children = []) => {
        const addBottomMargin = c =>
          c.type === 'p'
            ? { ...c, props: { ...c.props, className: `${c.props.className} mb-[4px]` } }
            : c;
        const content = children.map(addBottomMargin);

        return <li className="pl-[7px]">{content}</li>;
      },
      [BLOCKS.PARAGRAPH]: (_, children = []) => {
        const isEmpty = children.length === 1 && children[0] === '';
        if (isEmpty) {
          return <br />;
        }

        return <p className="text-body-1">{addNewLines(children)}</p>;
      },
    },
  };
};

export default function PageFaq({ resources }) {
  const faqMobileRef = useRef(null);

  const { asPath, push } = useRouter();

  const { resourcesCollection } = resources?.globalModuleCollection?.items[0];
  const getMediaByName = createMediaGetterFromCollection(resourcesCollection);
  const faqBanner = getMediaByName('faq_banner');
  const imageWithWriting = getMediaByName('faq-menu-image');
  const contactTitle = resourcesCollection?.items?.filter(filterByType('ComponentMicrocopy'))?.[0];
  const contactDescription = resourcesCollection?.items?.find(filterByType('ComponentRichText'));
  const faqLinks = resourcesCollection?.items
    ?.filter(filterByType('ComponentLink'))
    ?.filter(menu => !!menu?.link?.slug);
  const cleanedPathName = asPath.replaceAll('/', '');

  const faqMenus = resourcesCollection?.items.filter(filterByType('ComponentArticle'));
  const activeFaqSlug = faqMenus.find(m => m.slug === cleanedPathName)
    ? cleanedPathName
    : faqMenus[0].slug;
  const selectedMenu = faqMenus.find(m => m.slug === activeFaqSlug);
  const questionAndAnswers = selectedMenu?.submenusCollection?.items || [];

  const accordion = faqMenus.map((article, i) => (
    <ArticleAccordion
      article={article}
      index={i}
      key={article.title}
      className={`mx-[31.5px] lg:mx-0 ${
        article.slug === activeFaqSlug ? '' : 'hidden'
      } [&>button]:hidden [&>button]:lg:block`}
      titleClass="text-heading-6 mt-[var(--scrollMargin)] mb-[13px] lg:mx-0 lg:my-[0] font-nimbus-sans-extd-d font-bold uppercase md:cursor-pointer text-left"
      submenuTitleClass="text-heading-7 leading-[18.2px] font-nimbus-sans-extd-d font-bold uppercase md:cursor-pointer text-left"
      borders
    />
  ));

  const contactDetail = (
    <div>
      {contactTitle?.text && <p className="text-heading-7 mb-4">{contactTitle?.text}</p>}
      {contactDescription?.richText && (
        <RichText
          data={contactDescription?.richText}
          options={richTextOptions(contactDescription?.richText)}
        />
      )}
    </div>
  );

  const faqMenu = (
    <div className="mb-3 px-7 lg:mb-0 lg:px-[0]">
      {/* Desktop */}
      <div className="mb-[24px] hidden flex-col gap-3 lg:mb-12 lg:flex">
        {faqLinks.map(({ text, link: { slug } }) => (
          <SiteLink
            scroll={false}
            className={`text-heading-7 w-fit whitespace-nowrap uppercase ${
              slug === activeFaqSlug ? 'underline' : ''
            }`}
            href={slug}
          >
            {text}
          </SiteLink>
        ))}
      </div>

      {/* Mobile */}
      <div className="block lg:hidden">
        <CustomSelect
          options={faqLinks.map(({ text, link: { slug } }) => ({ label: text, value: slug }))}
          keepSelectedInList={false}
          dropdownGap={-5}
          values={[
            {
              label:
                faqLinks.find(({ link: { slug } }) => slug === activeFaqSlug)?.text ||
                faqLinks[0]?.text,
              value: activeFaqSlug,
            },
          ]}
          searchable={false}
          onChange={option => {
            push(`/${option?.at(0)?.value}`, undefined, { scroll: false });
          }}
          className="text-heading-7 border border-solid border-[rgba(4,28,44,0.20)] uppercase text-blue [&_.react-dropdown-select-content]:px-8 [&_.react-dropdown-select-content]:py-5 [&_.react-dropdown-select-dropdown-handle]:mr-8  [&_.react-dropdown-select-input]:hidden [&_.react-dropdown-select-item]:px-8 [&_.react-dropdown-select-item]:py-3"
        />
      </div>
      <div className="hidden lg:block">{contactDetail}</div>
    </div>
  );

  return (
    <>
      <FaqStructuredData faqs={questionAndAnswers} />
      <div className="relative mx-auto mb-[42px] h-[16.625rem] w-[92.5%] px-2 lg:mb-[0] lg:h-[17.5rem] lg:w-full">
        <SiteImage src={faqBanner} layout="fill" />
      </div>
      <div className="flex flex-col justify-center lg:grid lg:grid-cols-[1fr_3fr] lg:gap-16 lg:px-16">
        <div className="items-start justify-between lg:left-[60px] lg:top-[18rem] lg:flex lg:w-[345px] lg:translate-y-[-6.4rem] lg:flex-col lg:justify-start lg:bg-white lg:px-[45px] lg:pb-[12px] lg:pt-[40px]">
          <div className="relative w-[138px]">
            <SiteImage src={imageWithWriting} layout="responsive" />
          </div>
          <p className="text-heading-1 mb-9 text-center lg:mb-24 lg:text-left">FAQ</p>
          {faqMenu}
        </div>
        <div
          ref={faqMobileRef}
          style={{
            '--height': HEADER_HEIGHT,
            '--scrollMargin': '47px',
          }}
          className="mb-16 scroll-mt-[calc(var(--height)+var(--scrollMargin))] lg:hidden"
        >
          {accordion}
        </div>
        <div className="hidden max-w-[44.5vw] lg:block lg:border-none lg:pt-[97px]">
          {accordion}
        </div>
        <div className="mx-[31.5px] block lg:hidden">{contactDetail}</div>
      </div>
    </>
  );
}
